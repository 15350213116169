import axios from 'axios';

const apiClient = axios.create({
  // baseURL: 'http://139.59.183.165:3082', // local
  // baseURL: 'https://hc16.infoselfcloud.com/api-butransa', // dev
  // baseURL: 'https://dev.app.butransa.es/api-butransa', // preprod
  baseURL: 'https://cloud.butransa.es/api-butransa', // prod
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => {
    if (!response?.data?.data?.success && response?.data?.data?.code === 401) {
      localStorage.clear();
      window.location.href = '/butransa/login';
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/butransa/login';
    }
    return error;
  },
);
// axios.interceptors.request.use(
//   (config) => {
//     const newConfig = config;

//     newConfig.headers = {
//       'Content-type': 'application/json',
//       Accept: 'application/json',
//       'Access-Control-Allow-Origin': 'localhost:3000',
//       Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//     };

//     return newConfig;
//   }, (error) => Promise.reject(error),

// );

export default apiClient;
